import fullsizeImagesUI from './fullsize';
import slidersUI from './sliders';
import mapsUI from './maps-ui';
import readMoreUI from './read-more';
import filtersUI from './filtersUI';

;(function(window, document, $) {
	$('a[href^="tel"]').each(function() {
		const linkHref = $(this).attr('href');

		$(this).attr('href', linkHref.replace(/ /igm, ''));
	});

	$('.js-nav-btn').on('click', function(event) {
		event.preventDefault();
		
		$(this).toggleClass('active');
		$('.header .header__aside').slideToggle();
	});

	$('.js-scroll-to-section').on('click', function(event) {
		event.preventDefault();

		const $target = $($(this).attr('href'));

		console.log($target, $($(this).attr('href')))

		$('html, body').animate({
			scrollTop: $target.offset().top
		});
	});

	fullsizeImagesUI();

	slidersUI();

	mapsUI();

	readMoreUI();

	filtersUI();

	$('.js-toggle-search').on('click', function(event) {
		event.preventDefault();
		
		$('.search-bar').slideToggle();
	});
})(window, document, window.jQuery);
