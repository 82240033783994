import Filter from './filters';

const initFilters = () => {
	const filters = new Filter();

	const multipleFiltration = ($filters, filter) => {
		if ( filter.length <= 2 ) {
			filters.filterReset();
		} else {
			filters.filterByMultipleClass(filter, true);
		}
	}

	if ( filters.settings.container.length )  {
		filters.init();



		$('.js-filter-select').on('change', function() {
			const filter = $(this).val();

			if ( filter == '*' ) {
				filters.filterReset();
			} else {
				filters.filterByClass(filter);
			}
		});
	}

	if ( filters.settings.container.length )  {
		filters.init();

		const $containers = $('.js-filters');

		const drawTotalElements = () => {
			const $total = $('.js-filters-found');

			if ($total.length) {
				$total.text(filters.filteredElements.length);
			}
		}

		drawTotalElements();

		$('.js-filter').on('click', function() {
			const $this = $(this);
			const filter = $this.data('filter');

			$this
				.parent()
					.addClass('active')
				.siblings()
					.removeClass('active');

			if ( filter == '*' ) {
				filters.filterReset();
			} else {
				filters.filterByClass(filter);
			}
		});

		$containers.each(function() {
			const $filters = $('.js-filter-select');
			const $clear = $('.js-filters-clear');

			$clear.on('click', function(e) {
				filters.filterReset();

				$filters.each((i, filter) => {
					if ($(filter).is('input')) {
						$(filter).prop('checked', false);
					}
				});

				drawTotalElements();
			});

			$filters.on('change', function() {
				const categoryClasses = [];

				// Get active categories
				$filters.each((index, filter) => {
					if (filter.value == '*') return;

					if ($(filter).is('input')) {
						if ($(filter).is(':checked')) {
							categoryClasses.push(filter.value);
						} else {
							const filterIndex = categoryClasses.indexOf(filter.value);
							if (filterIndex > -1) {
								categoryClasses.splice(filterIndex, 1);
							}
						}
					} else {
						categoryClasses.push(filter.value);
					}
				});

				multipleFiltration($filters, categoryClasses.join(' '));

				drawTotalElements();
			});
		});

		initPrefilter();
	}

	$('.js-prefilter').on('click', function() {
		localStorage.prefilter = $(this).data('prefilter');
	});

	function initPrefilter() {
		if ( localStorage.prefilter ) {
			if ( $('.js-filters').length ) {
				$('html, body').animate({
					scrollTop: $('.js-filters').offset().top - $('.header').height()
				}, 300)
			}

			$(localStorage.prefilter).prop('checked', true).trigger('change');

			localStorage.removeItem('prefilter');
		}
	}
}

export default initFilters;
