import Isotope from '../vendor/isotope.pkgd.min.js';

class Filters {
	constructor(settings) {
		this.settings = {
			container: $('.js-filter-container'),
			elements: $('.js-filter-element'),
			hasIsotope: true,
			...settings
		};

		this.filteredElements = this.settings.elements;
		this.istp = null;
	}

	init() {
		if ( this.settings.hasIsotope ) {
			this.istp = this.settings.container;

			this.istp.isotope({
				transitionDuration: 0
			});
		}
	}

	filterReset() {
		this.filteredElements = this.settings.elements;

		this.filterIsotope();
	}

	filterIsotope() {
		const that = this;

		if ( this.settings.hasIsotope ) {
			this.istp.isotope({
				filter: function() {
					const $el = $(this);
					let isMatched = false;

					that.filteredElements.each(function() {
						if ( $(this).is($el) ) {
							isMatched = true;
						}
					});

					return isMatched;
				}
			});
		}
	}

	filterByClass(selector, elements = this.settings.elements) {
		this.filteredElements = elements.filter(function() {
			return $(this).hasClass(selector.replace('.', ''));
		});

		this.filterIsotope();
	}

	filterByDate(date, elements = this.settings.elements) {
		this.filteredElements = elements.filter(function() {
			return new Date($(this).data('date')).getTime() == date.getTime();
		});

		this.filterIsotope();
	}

	filterByDateRange(minDate, maxDate, elements = this.settings.elements) {
		this.filteredElements = elements.filter(function() {
			return shouldShowItemByDateRange($(this), minDate, maxDate);
		});

		this.filterIsotope();
	}

	// Selectors - string with clasess separated with spaces
	// Example - '.category-1 .category-2'
	filterByMultipleClass(selectors, isStrict = false, elements = this.settings.elements) {
		const selectorsArr = selectors.split(' ');

		this.filteredElements = elements.filter(function() {
			return shouldShowItemByMultipleClass($(this), selectorsArr, isStrict);
		});

		this.filterIsotope();
	}

	filterByRanges(rangesArr, elements = this.settings.elements) {
		let tempFilteredElements = elements;

		for (let i = 0; i < rangesArr.length; i++) {
			const range = rangesArr[i];

			tempFilteredElements = tempFilteredElements.filter(function() {
				return shouldShowItemByRange($(this), range.key, range.min, range.max);
			});
		}

		this.filteredElements = tempFilteredElements;

		this.filterIsotope();
	}

	filterByCustom(types) {
		// Demo types object
		/* {
			class: '.category-1'
			date: '2020/10/5',
			multipleClass: {
				classes: '.category-1 .category-2'
				strict: false
			},
			dateRange: {
				minDate: '2020/10/5',
				maxDate: '2020/10/6'
			},
			range: [{
				key: 'size',
				min: 50,
				max: 100
			}]
		}*/

		this.filteredElements = this.settings.elements;

		for (let type in types) {
			switch (type) {
				case 'class':
					this.filterByClass(types[type], this.filteredElements);
					break;

				case 'date':
					this.filterByDate(new Date(types[type]), this.filteredElements);
					break;

				case 'multipleClass':
					this.filterByMultipleClass(types[type].classes, types[type].strict ? types[type].strict : false, this.filteredElements);
					break;

				case 'dateRange':
					this.filterByDateRange(types[type].minDate, types[type].maxDate, this.filteredElements);
					break;

				case 'ranges':
					this.filterByRanges(types[type], this.filteredElements)
					break;

				default:
					console.log('Unmatched filter type - ' + type);
			}
		}
	}
}

function shouldShowItemByMultipleClass($el, selectorsArr, isStrict) {
	let shouldShowItem = false;
	let matchAll = true;
	let counter = 0;

	for (let i = 0; i < selectorsArr.length; i++) {
		const selector = selectorsArr[i];

		if (selector !== '' && selector !== '*') {
			if ( $el.hasClass(selector.replace('.', '')) ) {
				shouldShowItem = true;
			} else {
				if ( isStrict ) {
					matchAll = false;
				}
			}
		}

		if (selector === '*') {
			counter++;
		}
	}

	return counter === selectorsArr.length ? true : shouldShowItem && matchAll;
}

function shouldShowItemByDateRange($el, minDate, maxDate) {
	const date = new Date($el.data('date'));
	let shouldShowItem = false;
	const isAfterMinDate = date.getTime() >= minDate.getTime();
	const isBeforeMaxDate = date.getTime() <= maxDate.getTime();

	if ( isAfterMinDate && isBeforeMaxDate ) {
		shouldShowItem = true;
	}

	return shouldShowItem;
}

function shouldShowItemByRange($el, attribute, min, max) {
	const value = parseInt($el.data(attribute));
	let shouldShowItem = false;

	if ( min <= value && max >= value ) {
		shouldShowItem = true;
	}

	return shouldShowItem;
}

export default Filters;
