import 'readmore-js';
import { getCurrentDevice } from './utils';

const getMaxHeight = ($el, lines = 7) => {
	let tempoLines = parseInt($el.data('readMore'), 10);

	return ((tempoLines > 0) ? tempoLines : lines) * parseInt($el.css('line-height'),10) || 115
}

const showOnDevice = ($el) => {
	const dataValue = $el.data('device');
	let shouldShow = true;

	if (dataValue && typeof dataValue == 'string') {
		let devicesList = dataValue.split(',');

		if (devicesList.indexOf(getCurrentDevice()) < 0) {
			shouldShow = false;
		}
	}

	return shouldShow;
}

const initReadMore = ($container) => {
	const $readMore = $('#templateReadMore').html();
	const $readLess = $('#templateReadLess').html();

	$container.each(function(){
		const $el = $(this);

		if (showOnDevice($el)) {
			const maxHeight = getMaxHeight($el);

			$el.readmore({
				collapsedHeight: maxHeight,
				lessLink: $readLess,
				moreLink: $readMore,
			});
		} else {
			$el.readmore('destroy');
		}
	})
}

export default function init() {
	$('[data-read-more]').each(function() {
		initReadMore($(this));
	});
}
