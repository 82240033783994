import { $win, getCurrentDevice } from './utils';

const styles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#181818"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1b1b1b"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#2c2c2c"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8a8a8a"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#373737"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#4e4e4e"
      }
    ]
  },
  {
    "featureType": "road.local",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#3d3d3d"
      }
    ]
  }
];

const initMap = () => {
	const $mapContainers = $('.map');

	if ($mapContainers.length) {
		$mapContainers.each((index, el) => {
			const $mapContainer = $(el);
			const $map = $mapContainer.find('.map__iframe');
			const mapLat = $map.data('lat') || 0;
			const mapLng = $map.data('lng') || 0;
			const zoom = $map.data('zoom') || 17;
			const $infoboxes = $mapContainer.find('.map__infobox');
			let markers = [];

			let selectedInfoWindow;

			// const bounds = new google.maps.LatLngBounds();

			// Map Options
			const mapOptions = {
				styles,
				zoom,
				center: new google.maps.LatLng(mapLat, mapLng),
				disableDefaultUI: true
			};

			const map = new google.maps.Map($map[0], mapOptions);

			// Handle Infobox
			$infoboxes.each((index, el) => {
				const $this = $(el);
				const lat = $this.data('lat');
				const lng = $this.data('lng');
				const pin = $this.data('pin');

				const pinMarker = {
					url: pin,
					size: new google.maps.Size(21, 37),
					scaledSize: new google.maps.Size(21, 37)
				}

				const position = new google.maps.LatLng(lat, lng);

				// bounds.extend(position);

				const marker = new google.maps.Marker({
					position,
					map,
					icon: pinMarker
				});
			});

			$('.js-center-map').each(function(index, el) {
				$(el).on('click', function(event) {
					map.setCenter({
						lat : $(this).data('lat'),
						lng : $(this).data('lng')
					});
				});
			});

			// map.fitBounds(bounds);
		});
	}
};

export default function mapsUI() {
	initMap();
}
